import React, { useState, useEffect }  from "react";
import { useNavigate, useLocation } from "react-router-dom";
import emailjs from 'emailjs-com';
import { createRequest, updateAccount, postSickNote } from "../../api";

function RequestForm(props){
  const getTodayString = () => {
    const [month, day, year] = new Date()
      .toLocaleDateString()
      .split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };
  //const { requests, accounts } = props;
  const {sickRequest} = props
  const location = useLocation();
  const { foundAccount } = location.state;

  const [startDate, setStartDate] = useState(getTodayString);
  const [endDate, setEndDate] = useState(getTodayString);
  const [validated,setValidated] = useState(false);
  const [dayDiff,setDayDiff] = useState(0);
  const [daysOffPermitted,setDaysOffPermitted] = useState(foundAccount.daysOff);
  //const [sickRequest,setSickRequest] = useState(false);
  const [sickNote,setSickNote] = useState(null);
  const [uploadedFileURL, setUploadedFileURL] = useState(null);

  const navigate = useNavigate();
  const navigateToHome = () => navigate('/home',{state:{foundAccount}});

  useEffect(() => {
    const startingDate = new Date(Date.parse(startDate));
    const endingDate = new Date(Date.parse(endDate));

    const dateDiff = Math.floor((endingDate - startingDate) / (1000 * 60 * 60 * 24));
    setDayDiff(dateDiff);
    setValidated((dayDiff > 0)&&(dayDiff < daysOffPermitted));
    //console.log(sickNote)
  });

  const validateRequest = () => {
      if(dayDiff <= 0){
        alert("Invalid dates selection.");
      }
      else if(dayDiff > daysOffPermitted){
        alert("Number of days off selected exceeds current PTO assigned.")
      }
      else
      {
        setValidated(true);
      }
    };

  const handleStartDate = (e) => {
    const [month, day, year] = (new Date(e.target.value)).toLocaleDateString().split("/");
    setStartDate(`${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`);
  };

  const handleEndDate = (e) => {
    const [month, day, year] = (new Date(e.target.value)).toLocaleDateString().split("/");
    setEndDate(`${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`);
  };

  const handleSickNoteChange = (e) => {
    setSickNote(e.target.files[0]);
    setUploadedFileURL(URL.createObjectURL(e.target.files[0]));
  }

  //const handleRequestChange = (e) => {
  //  setSickRequest(!sickRequest);
  //}

  const handleSubmit = (e) => {
    e.preventDefault();
    validateRequest();

    if(validated){
      const templateEmail = {
        emailName: foundAccount.firstName,
        emailStartDate: startDate,
        emailEndDate: endDate
      };
      if(!sickRequest){
        setSickNote(null);
        //postSickNote(sickNote);
      }
      let requestObject = {
        email: foundAccount.email,
        accountid: foundAccount._id,
        name: foundAccount.firstName + " " + foundAccount.lastName,
        startDate: startDate,
        endDate: endDate,
        approved: false,
        note: sickNote
      }
      createRequest(requestObject);
      
      let accountObject = {
        username: foundAccount.username,
        management: foundAccount.management,
        firstName: foundAccount.firstName,
        lastName: foundAccount.lastName,
        email: foundAccount.email,

        
        password: foundAccount.password,
        region: foundAccount.region,
        daysOff: foundAccount.daysOff - dayDiff
      }
      updateAccount(foundAccount._id,accountObject);
  
      emailjs.send('service_cdwvpqe','template_gjc70g5', templateEmail, '5IQFPgP9Nk8yTA2hi')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      }, (err) => {
        console.log('FAILED...', err);
      });
      alert(`Request Email Sent`);
      navigateToHome();
    }
  };

  //<label>{new Date(Date.parse(endDate)).toLocaleDateString('en-GB')}</label>

  if(!sickRequest){
  return(
    <div style={{ width: "50%", transform:" translate(0%)",transitionDuration: "600ms"}}>
    <form onSubmit={handleSubmit}>
        <label>PTO Request</label>
        <input 
            id="startDate"
            name="startDate"
            type="date"
            value={startDate}
            onChange={handleStartDate}
        />
        <input 
            id="endDate"
            name="endDate"
            type="date"
            value={endDate}
            onChange={handleEndDate}
            
        />
        <h1>{`Days off Permitted : ${daysOffPermitted}`}</h1>
        <h1>{`Days off selected : ${dayDiff}`}</h1>
        <button type="submit">Submit Request </button>
    </form>
    </div>

  );
}
else {
  return(
    <div style={{ width: "100%", transform:"translate(0%)",transitionDuration: "600ms", display:"flex", flexDirection: "row-reverse",}}>
      <div style={{ width: "50%", flexDirection: "row-reverse" }}>
      <form onSubmit={handleSubmit}>
          <label>Sick Leave Request</label>
          <input 
              id="startDate"
              name="startDate"
              type="date"
              value={startDate}
              onChange={handleStartDate}
          />
          <input 
              id="endDate"
              name="endDate"
              type="date"
              value={endDate}
              onChange={handleEndDate}
          />
          <input 
              id="sickNote"
              name="sickNote"
              type="file"
              onChange={handleSickNoteChange}
          />
          <h1>{`Days off Permitted : ${daysOffPermitted}`}</h1>
          <h1>{`Days off selected : ${dayDiff}`}</h1>
          <button type="submit">Submit Request </button>
      </form>
      </div>
      <div style={{ width: "40%", display:"flex", placeContent:"center", justifyContent: "center", alignContent:"center"}}>
        <img style={{justifyContent:"center", height: "40%",margin: "50px"}}src={uploadedFileURL}/>
      </div>
    </div>

  );
}
}

export default RequestForm;
