import React, { useState, useEffect } from "react";
import styles from "./LoginPage.module.css";
import { deleteRequest, getRequest, getAccount, updateAccount, updateRequest } from "../api";
import AccountForm from "../components/accountForm/AccountForm";

function ManageAccountsPage (props) {
    const { requests, accounts } = props;

    const [createAccount,setCreateAccount] = useState(false);

    function handleClick() {
        setCreateAccount(true);
    }
    
    function handleSubmit() {
        setCreateAccount(false);
    }

    if (!createAccount){
    return(
        <div style={styles}>
            <label>Manage Employee Accounts</label>
            <table style={{width:"50%", margin:"0 auto"}}>
            <tr>
                <th>Name</th>
                <th>Days Off</th>
                <th>Hourly Wage</th>
            </tr>
            {accounts.filter(filteredAccount => filteredAccount.management === false).map(account => 
            <tr>
                <td>{account.username}</td>
                <td>{account.daysOff}</td>
                <td>empty</td>
            </tr>
            )}
            <tr>
                <td><button onClick={handleClick}>Add New Employee Account</button></td>
            </tr>
            </table>
        </div>
    );
    }
    else{
        return(
            <div style={styles}>
                <label>Manage Employee Accounts</label>
                <table style={{width:"50%", margin:"0 auto"}}>
                <tr>
                    <th>Name</th>
                    <th>Days Off</th>
                    <th>Hourly Wage</th>
                </tr>
                {accounts.filter(filteredAccount => filteredAccount.management === false).map(account => 
                <tr>
                    <td>{account.username}</td>
                    <td>{account.daysOff}</td>
                    <td>empty</td>
                </tr>
                )}
                <tr>
                    <td><AccountForm accounts={accounts} proxycreated={true} onSubmit={handleSubmit}/></td>
                </tr>
                </table>
            </div>
        );
    }
}

export default ManageAccountsPage;