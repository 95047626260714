import React, { useState, useEffect } from "react";
import styles from "./LoginPage.module.css";
import { deleteRequest, getRequest, getAccount, updateAccount, updateRequest } from "../api";

function ManageRequestsPage (props) {
    const { requests } = props;

    const handleApprove = async (e) => {
        const oldRequest = await getRequest(e.target.value);
        let newRequest = {
            email: oldRequest.email,
            accountid: oldRequest.accountid,
            name: oldRequest.name,
            startDate: oldRequest.startDate,
            endDate: oldRequest.endDate,
            approved: true,
            note: oldRequest.note
        }
        updateRequest(oldRequest._id,newRequest);

        const dateDiff = Math.floor((Date.parse(newRequest.endDate) - (Date.parse(newRequest.startDate))) / (1000 * 60 * 60 * 24));
        const oldAccount = await getAccount(oldRequest.accountid);
        console.log(dateDiff);
        let newAccount = {
            username: oldAccount.username,
            management: oldAccount.management,
            firstName: oldAccount.firstName,
            lastName: oldAccount.lastName,
            email: oldAccount.email,
            password: oldAccount.password,
            region: oldAccount.region,
            daysOff: oldAccount.daysOff + dateDiff
        }
        updateAccount(oldRequest.accountid,newAccount);
    }

    const handleDeny = (e) => {
        deleteRequest(e.target.value);
    }


    return(
        <div style={styles}>
            <label>Manage PTO Requests</label>
            <table style={{width:"50%", margin:"0 auto"}}>
            <tr>
                <th>Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Approve/Deny</th>
            </tr>
            {requests.filter(filteredRequest => filteredRequest.approved === false).map(request => 
            <tr>
                <td>{request.name}</td>
                <td>{request.startDate}</td>
                <td>{request.endDate}</td>
                <td  style={{width:"30%"}}>
                    <button style={{width: "100px", marginRight:"10px", display:"inline", background:"#15661d"}} onClick={handleApprove} value={request._id}>Approve</button>
                    <button style={{width: "100px", marginLeft:"10px", display:"inline", background:"#820919"}} onClick={handleDeny} value={request._id}>Deny</button>
                </td>
            </tr>)}
            </table>
        </div>
    );
}

export default ManageRequestsPage;