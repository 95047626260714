import axios from "axios";

const URL = "http://localhost:3000";
//const URL = process.env.REACT_APP_APP_HOST;

//Functions for Request Database

export async function postSickNote(file){
    console.log(file);
    const formData = new FormData();
    formData.append(
        "FILE",
        file,
        file.name
    );
    //formData.append('file', file);
    //formData.append('fileName', file.name);
    console.log(formData.get("FILE"));
    const response = await axios.post(`${URL}/uploadFile`, formData);
    console.log(response.data);
    return response.data;
}

export async function getRequests() {
    const response = await axios.get(`${URL}/requests`);
    if(response.status === 200){
        return response.data;
    }
    else{
        return;
    }
}

export async function createRequest(request) {
    const response = await axios.post(`${URL}/requests`, request);
    return response;
}

export async function updateRequest(id, request) {
    const response = await axios.put(`${URL}/requests/${id}`, request);
    return response;
}

export async function getRequest(id) {
    const response = await axios.get(`${URL}/requests/${id}`);
    if(response.status === 200){
        return response.data;
    }
    else{
        return;
    }
}

export async function deleteRequest(id){
    const response = await axios.delete(`${URL}/requests/${id}`);
    return response;
}

//Functions for Accounts Database

export async function getAccounts() {
    const response = await axios.get(`${URL}/accounts`);
    if(response.status === 200){
        return response.data;
    }
    else{
        return;
    }
}

export async function createAccount(account) {
    const response = await axios.post(`${URL}/accounts`, account);
    return response;
}

export async function updateAccount(id, account) {
    const response = await axios.put(`${URL}/accounts/${id}`, account);
    return response;
}

export async function getAccount(id) {
    const response = await axios.get(`${URL}/accounts/${id}`);
    if(response.status === 200){
        return response.data;
    }
    else{
        return;
    }
}

export async function deleteAccount(id){
    const response = await axios.delete(`${URL}/accounts/${id}`);
    return response;
}